import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { get, noop } from 'lodash';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { DateField, NumberField } from '../../../../General';

const OneTimeInvestment = React.memo(({
  data, onChange, validations, showValidations, language
}) => {
  return (
    <>
      <Row className="provision-depot-opening--space--top">
        <Col md={3} className="provision-depot-opening--label--center">
          <FormattedMessage id="Provision.Form.DepotOpening.Execution.OneTimeInvestment.AmountInChf" />
        </Col>
        <Col md={9}>
          <NumberField
            id="amount"
            value={get(data, 'amount')}
            onChange={onChange}
            showValidations
            validations={get(validations, 'amount')}
          />
        </Col>
      </Row>
      <Row className="provision-depot-opening--space--top">
        <Col md={3} className="provision-depot-opening--label--center">
          <FormattedMessage id="Provision.Form.DepotOpening.Execution.OneTimeInvestment.ExecuteOn" />
        </Col>
        <Col md={9}>
          <DateField
            id="executionStartDate"
            name="executionStartDate"
            value={get(data, 'executionStartDate')}
            minDate={get(data, 'selectedAccount.noticeNecessary', false) ? moment().add('d', 31) : moment()}
            onChange={onChange}
            onInputChange={onChange}
            showValidations={showValidations}
            validations={get(validations, 'executionStartDate')}
            language={language}
          />
        </Col>
      </Row>
      <Row className="provision-depot-opening--space--top">
        <Col md={12} className="provision-depot-opening--label--center">
          <FormattedMessage id="Provision.Form.DepotOpening.Execution.OneTimeInvestment.FondInvestmentInfo" />
        </Col>
      </Row>
    </>
  );
});

OneTimeInvestment.propTypes = {
  validations: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func,
  showValidations: PropTypes.bool,
  language: PropTypes.string
};

OneTimeInvestment.defaultProps = {
  onChange: noop,
  data: {},
  showValidations: true,
  language: 'de'
};

export default OneTimeInvestment;
