import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, FormGroup, Label, Button, Row } from 'reactstrap';
import moment from 'moment';
import { get, isEmpty, isEqual, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { WorkguideCustomerAware } from '../../../Workguide';
import { DateField } from '../../../General';
import provisionActions from '../../actions/Actions';
import { getValidator } from '../../../../globals';
import { Section, SectionContent, SectionTitle } from '../../../General/components/Section';
import getWithdrawalValidFormToValidations from '../../lib/getWithdrawalValidFormToValidations';
import AccountSelect from '../AccountSelect/AccountSelect';

class WithdrawalLimitCancellationForm extends PureComponent {
  constructor(props) {
    super(props);

    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onChangeAccount = this.onChangeAccount.bind(this);
    this.onDateFromChanged = this.onDateFromChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.withdrawalLimitCancellationFormReset();
  }

  /**
   * Event handler for when the customer changes.
   * Triggers an action to request provision accounts.
   */
  onCustomerChange() {
    const { actions, customer } = this.props;
    const customerId = get(customer, 'id');
    actions.provisionAccountsRequest({ dataKey: customerId });
  }

  onChangeAccount(id, value) {
    const { form, actions } = this.props;

    const updatedData = {
      ...get(form, 'data'),
      selectedAccount: value
    };

    // resets form values and set account in form data
    actions.withdrawalLimitCancellationFormInitRequest({ data: updatedData });
  }

  /**
   * Event handler for when the 'dateFrom' field changes.
   * Updates the form state with new date values.
   *
   * @param {string} key - The key of the date field
   * @param {string} value - The new date value
   */
  onDateFromChanged(key, value) {
    const { actions } = this.props;
    actions.withdrawalLimitCancellationFormSetValue(key, value);
    actions.withdrawalLimitCancellationFormSetValue('validUntil', moment(value).add(3, 'month').subtract(1, 'day').format());
  }

  onSubmit() {
    const {
      actions, form, customer, consultant, toggleRedirectModal
    } = this.props;
    const customerId = get(customer, 'id');
    const consultantId = get(consultant, 'id');

    actions.withdrawalLimitCancellationFormSaveRequest({ data: get(form, 'data'), customerId, consultantId });
    toggleRedirectModal();
  }

  /**
   * Validate the form data
   *
   * @return {Object} validations Validation result
   */
  validate() {
    const { form } = this.props;

    const validator = getValidator();

    const validFromAndUntilValidation = getWithdrawalValidFormToValidations(form);

    const definition = {
      validations: {
        selectedAccount: {
          type: 'object',
          required: true
        },
        validFrom: {
          type: 'string',
          required: true,
          validations: get(validFromAndUntilValidation, 'validFrom', {})
        },
        validUntil: {
          type: 'string',
          required: true,
          validations: get(validFromAndUntilValidation, 'validUntil', {})
        }
      }
    };

    return validator.validate(definition, get(form, 'data', {}));
  }

  render() {
    const {
      provisionAccountsFullfiled, provisionAccountErrors, form, customer, redirect, language
    } = this.props;
    const data = get(form, 'data', {});
    const validations = this.validate();

    return (
      <WorkguideCustomerAware
        onChange={this.onCustomerChange}
        onInit={this.onCustomerChange}
      >
        <AccountSelect
          customer={customer}
          validations={validations}
          selectedAccount={get(data, 'selectedAccount')}
          onChange={this.onChangeAccount}
        />

        <Section>
          <SectionTitle>
            <FormattedMessage id="Provision.Form.WithdrawalLimitCancellationForm.Createtitle" />
          </SectionTitle>
          <Row>
            <Col style={{ display: 'flex', paddingBottom: '1em' }}>
              <FormattedMessage id="Provision.Form.WithdrawalLimitCancellationForm.CancellationDescription1" />
              {' '}
              <FormattedMessage id="Provision.Form.WithdrawalLimitCancellationForm.CancellationDescription2" />
            </Col>
          </Row>

          <SectionContent>
            <FormGroup row>
              <Label sm={3}>
                <FormattedMessage id="Provision.Form.WithdrawalLimitCancellationForm.ValidFrom" tagName="b" />
              </Label>
              <Col sm={5}>
                <DateField
                  id="validFrom"
                  name="validFrom"
                  value={get(data, 'validFrom')}
                  minDate={moment().add(31, 'days')}
                  onChange={this.onDateFromChanged}
                  onInputChange={this.onDateFromChanged}
                  showValidations
                  validations={get(validations, 'validFrom')}
                  language={language}
                />

              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3}>
                <FormattedMessage id="Provision.Form.WithdrawalLimitCancellationForm.ValidTo" tagName="b" />
              </Label>
              <Col sm={5}>
                <DateField
                  id="validUntil"
                  name="validUntil"
                  disabled
                  value={get(data, 'validUntil')}
                  validations={get(validations, 'validUntil')}
                  language={language}
                />
              </Col>
            </FormGroup>

          </SectionContent>
        </Section>
        <div className="mt-5">
          <Button
            color="primary"
            disabled={!isEmpty(validations) || (!isEqual(provisionAccountErrors, false) || !provisionAccountsFullfiled)}
            onClick={this.onSubmit}
          >
            <FormattedMessage id="Provision.Form.WithdrawalLimitCancellationForm.SubmitButton" />
          </Button>
          <span style={{ paddingRight: '20px' }} />
          <Button color="primary" outline onClick={() => redirect()}>
            <FormattedMessage id="General.Cancel" />
          </Button>
        </div>
      </WorkguideCustomerAware>
    );
  }
}

WithdrawalLimitCancellationForm.propTypes = {
  actions: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  consultant: PropTypes.object.isRequired,
  provisionAccountErrors: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  provisionAccountsFullfiled: PropTypes.bool,
  toggleRedirectModal: PropTypes.func,
  redirect: PropTypes.func,
  language: PropTypes.string
};
WithdrawalLimitCancellationForm.defaultProps = {
  provisionAccountErrors: false,
  provisionAccountsFullfiled: false,
  toggleRedirectModal: noop,
  redirect: noop,
  language: 'de'
};

function mapStateToProps(state, ownProps) {
  const customerId = get(ownProps, 'customer.id');
  return {
    provisionAccounts: get(state, `provision.provisionAccounts.${customerId}`),
    provisionAccountErrors: get(state, `provision.provisionAccounts.${customerId}.error`),
    provisionAccountsFullfiled: get(state, `provision.provisionAccounts.${customerId}.fulfilled`),
    form: state.provision.withdrawalLimitCancellationForm,
    consultant: state.login.session,
    language: state.login.language,
  };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(provisionActions, dispatch) };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WithdrawalLimitCancellationForm));
