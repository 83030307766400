import { put, call } from 'redux-saga/effects';
import { get, isUndefined } from 'lodash';

import actions from '../../actions/Actions';
import getAddresses from '../../../Address/sagas/Customer/getAddresses';
import getDispatchRules from '../../../Address/sagas/Customer/getDispatchRules';

const {
  CREATE_COVER_LETTER_WORKGUIDE_FORM_INIT_PENDING,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_INIT_FULFILLED,
  CREATE_COVER_LETTER_WORKGUIDE_FORM_FORM_INIT_REJECTED
} = actions;

export default function* initForm(request) {
  const { customer } = request;

  yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_INIT_PENDING });

  try {
    const addresses = yield call(getAddresses, { customerId: get(customer, 'id') });
    const dispatchRules = yield call(getDispatchRules, { customerId: get(customer, 'id') });

    const defaultAddresses = addresses.find((address) => get(address, 'addressType.id') === 'addressType-11' && get(address, 'country.id') === 'country-1');
    const payload = {
      address: !isUndefined(defaultAddresses) ? defaultAddresses : undefined,
      showDispatchRulesInfo: get(dispatchRules, 'length', 0) > 0,
      checkboxes: ['Supplement'],
      subject: '',
      description: '',
      replyEnvelope: false,
      customer,
      addresses,
      consultant: get(customer, 'consultant')
    };

    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_INIT_FULFILLED, payload });

    return true;
  } catch (error) {
    yield put({ type: CREATE_COVER_LETTER_WORKGUIDE_FORM_FORM_INIT_REJECTED, error });

    return error;
  }
}
