import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';
import { noop } from 'lodash';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import StandingOrder from './Execution/StandingOrder';
import OneTimeInvestment from './Execution/OneTimeInvestment';

class Execution extends PureComponent {
  constructor(props) {
    super(props);

    const { onChange } = this.props;

    this.state = {
      activeTab: 'standingOrder'
    };

    this.onChangeTab = this.onChangeTab.bind(this);
  }

  onChangeTab(activeTab) {
    const { onChange } = this.props;

    // if activeTab is not like 'oneTimeInvestment' it will be set leater, through a dropdown field,
    // this is the reason it writes undefined in the else statement
    if (activeTab === 'oneTimeInvestment') {
      onChange('executionType', activeTab);
    } else {
      onChange('executionType', undefined);
    }

    this.setState({ activeTab });
  }

  /**
   * Render the tab navigation
   *
   * @return {ReactElement} markup
   */
  renderTabNav() {
    const { activeTab } = this.state;

    return (
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === 'oneTimeInvestment'}
            onClick={() => this.onChangeTab('oneTimeInvestment')}
          >
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.Tabs.OneTimeInvestment" />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={activeTab === 'standingOrder'}
            onClick={() => this.onChangeTab('standingOrder')}
          >
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.Tabs.StandingOrder" />

          </NavLink>
        </NavItem>
      </Nav>
    );
  }

  /**
   * Render the tab content
   *
   * @return {ReactElement} markup
   */
  renderTabContent() {
    const { activeTab } = this.state;
    const {
      onChange, data, validations, language
    } = this.props;

    return (
      <TabContent activeTab={activeTab}>
        <TabPane tabId="oneTimeInvestment">
          <OneTimeInvestment
            onChange={onChange}
            validations={validations}
            data={data}
          />
        </TabPane>

        <TabPane tabId="standingOrder">
          <StandingOrder
            onChange={onChange}
            validations={validations}
            data={data}
            language={language}
          />
        </TabPane>
      </TabContent>
    );
  }

  render() {
    return (
      <>
        <Row className="provision-depot-opening--execution-title-row">
          <Col md={3}>
            <FormattedMessage id="Provision.Form.DepotOpening.Execution.Title" tagName="h3" />
          </Col>
        </Row>
        <Row>
          {this.renderTabNav()}
          {this.renderTabContent()}
        </Row>
      </>
    );
  }
}

Execution.propTypes = {
  validations: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func,
  language: PropTypes.string
};

Execution.defaultProps = {
  onChange: noop,
  data: {},
  language: 'de'
};

export default injectIntl(Execution);
