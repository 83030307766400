import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Tooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { withCodeGroups } from '@evoja-web/entity-code';

import resolveSuspensionRef from '../../../../lib/Card/Utils/resolveSuspensionRef';

class SuspensionTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen });
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const { isOpen } = this.state;
    const {
      card,
      children,
      codes,
      language,
      target,
      placement
    } = this.props;
    const suspension = resolveSuspensionRef(
      get(card, 'suspension', []),
      get(codes, 'groups.creditCardSuspension', [])
    );

    return (
      <Tooltip
        target={target}
        placement={placement}
        isOpen={isOpen}
        toggle={this.toggle}
      >
        {suspension ? get(suspension, `text.${language}`) : <FormattedMessage id="Customer.CardAndProduct.NoSuspension" />}
        {children}
      </Tooltip>
    );
  }
}

SuspensionTooltip.propTypes = {
  card: PropTypes.object.isRequired,
  children: PropTypes.node,
  codes: PropTypes.object,
  language: PropTypes.string,
  target: PropTypes.string.isRequired,
  placement: PropTypes.string
};

SuspensionTooltip.defaultProps = {
  children: null,
  codes: { groups: {} },
  language: 'de',
  placement: 'right'
};

function mapStateToProps(state) {
  return {
    language: state.login.language
  };
}

export default connect(mapStateToProps)(
  withCodeGroups({ groups: ['creditCardSuspension'] })(SuspensionTooltip)
);
