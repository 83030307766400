import React from 'react';
import PropTypes from 'prop-types';
import { get, debounce, noop, isUndefined, has } from 'lodash';
import { Col, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { NumberField, Select, DateField } from '../../../../General/index';

const availableCurrencies = [
  'currency-1',
  // 'currency-814',
  // 'currency-333'
];

class Payment extends React.Component {
  constructor(props) {
    super(props);

    const {
      codes,
      onChange,
      value
    } = props;

    this.state = {
      debitAccount: undefined,
      currency: get(codes, 'groups.currency', []).find((c) => get(c, 'id') === 'currency-1'),
      amount: undefined,
      executionDate: undefined,
      ...value
    };

    this.onChange = debounce(onChange, 500);
    this.onValueChange = this.onValueChange.bind(this);
    this.getActivePaymentAccounts = this.getActivePaymentAccounts.bind(this);
  }

  componentDidMount() {
    const {
      codes,
      onChange,
      value = {}
    } = this.props;

    const currency = get(codes, 'groups.currency', []).find((c) => get(c, 'id') === 'currency-1');

    onChange('payments', { currency, ...value });
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    onChange('payments');
  }

  onValueChange(field, value) {
    const updated = {
      ...this.state,
      [field]: value
    };

    this.setState(updated);

    this.onChange('payments', updated);
  }

  getActivePaymentAccounts() {
    const { paymentAccounts } = this.props;

    return get(paymentAccounts, 'data', []).filter((paymentAccount) => !has(paymentAccount, 'closedDate'));
  }

  /**
   * Render method
   *
   * @return {ReactElement} markup
   */
  render() {
    const {
      debitAccount,
      currency,
      amount,
      executionDate
    } = this.state;
    const {
      codes,
      language,
      validations
    } = this.props;

    const accountOptions = this.getActivePaymentAccounts()
      .map((a) => ({
        value: get(a, 'id'),
        account: a,
        label: `${get(a, `accountType.text.${language}`)} (${get(a, 'numberFormatted')}) - ${get(a, 'customer.designation')}`
      }));

    const currencyOptions = get(codes, 'groups.currency', [])
      .filter((c) => availableCurrencies.includes(get(c, 'id')))
      .map((c) => ({
        value: get(c, 'id'),
        code: c,
        label: get(c, `textShort.${language}`)
      }));

    return (
      <React.Fragment>
        <Col sm={12} md={3} lg={3}>
          <Label>
            <FormattedMessage id="SecuritiesAccount.Opening.Form.DebitAccount" />
          </Label>

          <Select
            id="debitAccount"
            options={accountOptions}
            value={get(debitAccount, 'id')}
            onChange={this.onValueChange}
            returnValue="account"
            showValidations
            validations={validations.debitAccount}
          />
        </Col>

        <Col sm={12} md={3} lg={3}>
          <Label>
            <FormattedMessage id="SecuritiesAccount.Opening.Form.Currency" />
          </Label>

          <Select
            id="currency"
            options={currencyOptions}
            value={get(currency, 'id')}
            onChange={this.onValueChange}
            returnValue="code"
            showValidations
            validations={validations.currency}
          />
        </Col>

        <Col sm={12} md={3} lg={3}>
          <Label>
            <FormattedMessage id="SecuritiesAccount.Opening.Form.DebitAccountAmount" />
          </Label>

          <NumberField
            id="amount"
            name="amount"
            value={amount}
            onChange={this.onValueChange}
            isAllowed={({ floatValue }) => isUndefined(floatValue) || (floatValue >= 0 && Number.isInteger(floatValue))}
            decimalSeparator={false}
            showValidations
            validations={validations.amount}
          />
        </Col>

        <Col sm={12} md={3} lg={3}>
          <Label>
            <FormattedMessage id="SecuritiesAccount.Opening.Form.ExecutionDate" />
          </Label>

          <DateField
            id="executionDate"
            name="executionDate"
            value={executionDate}
            onChange={this.onValueChange}
            minDate={moment().startOf('day')}
            monthsShown={1}
            showValidations
            validations={validations.executionDate}
            language={language}
          />
        </Col>
      </React.Fragment>
    );
  }
}

Payment.propTypes = {
  codes: PropTypes.object,
  language: PropTypes.string,
  onChange: PropTypes.func,
  paymentAccounts: PropTypes.object,
  validations: PropTypes.object,
  value: PropTypes.object
};

Payment.defaultProps = {
  codes: { groups: {} },
  language: 'de',
  onChange: noop,
  paymentAccounts: {},
  validations: {},
  value: undefined
};

export default Payment;
