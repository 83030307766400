import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { get, head, isEmpty, isNil, noop } from 'lodash';
import { withCodeGroups, utils as codeUtils } from '@evoja-web/entity-code';
import { Button, Col, Row } from 'reactstrap';
import { Alert } from '@evoja-web/react-layout-components';
import cardActions from '../../actions/Actions';
import { withWorkguideInstanceData } from '../../../Workguide/hocs';
import WithCardSelect from '../../components/Card/Form/WithCardSelect';
import { SuspensionReason, SuspensionStatus } from '../../components/Card/Form/Fields';
import { getTranslationKey } from '../../../../staticMappings/mapVisecaCodes';
import { filterCardSuspensionCodes, filterCardStatusOptions } from '../../lib/Suspensions';
import { getValidator } from '../../../../globals';
import withCustomerCardData from '../../hocs/withCustomerCardData';
import { cardTypeMap } from '../../../../staticMappings/cards';
import { workguideMap } from '../../../../staticMappings/workguides';

export const cardStatusOptions = [{
  value: 'active',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'active')} />
}, {
  value: 'blocked',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'blocked')} />
}, {
  value: 'suspended_by_cardholder',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'suspended_by_cardholder')} />
}, {
  value: 'suspended_by_bank',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'suspended_by_bank')} />
}, {
  value: 'suspended_by_issuer',
  label: <FormattedMessage id={getTranslationKey('cardStatusMap', 'suspended_by_issuer')} />
}];

class CardSuspension extends PureComponent {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    const { cardActions, activity } = this.props;

    cardActions.cardSuspensionFormInitRequest({
      formData: get(activity, 'workguideData.formData.formData', get(activity, 'workguideData.formData'))
    });
  }

  /**
   * Form submit
   *
   * @return void
   */
  onSubmit() {
    const {
      cardActions,
      customer,
      intl,
      language,
      session,
      workguide,
      redirect,
      cardInfo,
      formData
    } = this.props;

    cardActions.cardSuspensionFormSaveRequest({
      customer,
      session,
      formData,
      language,
      workguide,
      cardInfo,
      intl
    });
    redirect();
  }

  /**
   * Check if the reason field is required
   *
   * @return {Boolean}
   */
  isReasonRequired() {
    const { formData } = this.props;

    return ['suspended_by_cardholder', 'suspended_by_bank'].includes(get(formData, 'status'));
  }

  /**
   * Check if the card was suspended by the issuer (viseca)
   *
   * @return {Boolean}
   */
  isSuspendedByIssuer() {
    const { formData } = this.props;
    return get(formData, 'status') === 'suspended_by_issuer';
    // return get(head(selected), 'detail.status.status') === 'suspended_by_issuer';
  }

  /**
   * Validate
   *
   * @return {Object} validations Validation result
   */
  validate() {
    const { formData } = this.props;
    const definition = {
      validations: {
        selected: {
          type: 'array',
          required: true
        },
        status: {
          type: 'string',
          required: true
        },
        reason: {
          type: 'object',
          required: this.isReasonRequired()
        }
      }
    };

    const validator = getValidator();

    return validator.validate(definition, formData);
  }

  renderAlert() {
    const { customer } = this.props;

    const link = (...value) => {
      const url = `/activity/${workguideMap.get('card-mutation')}/${get(customer, 'id')}`;

      return (
        <span
          className="react-anchor pointer"
          onClick={() => window.open(url, '_blank')}
        >
          {value}
        </span>
      );
    };

    return (
      <Alert color="warning" style={{ textAlign: 'center' }}>
        <h5>
          <FormattedMessage id="Card.Error.SuspensionByIssuer.Title" />
        </h5>

        <p>
          <FormattedMessage id="Card.Error.SuspensionByIssuer.Description" />
        </p>

        <p>
          <FormattedMessage
            id="Card.Error.SuspensionByIssuer.Hint"
            values={{
              wgl: link
            }}
          />
        </p>
      </Alert>
    );
  }

  render() {
    const {
      redirect, language, codes, customer, cardInfo, formData, cardActions, activity
    } = this.props;

    const disabled = !isNil(activity);

    const details = get(cardInfo, `cardDetails.${get(head(get(formData, 'selected', [])), 'id')}.data`);
    const suspensionCodes = get(codes, 'groups.creditCardSuspension', []);
    const validations = this.validate();
    const filteredCardStatusOptions = disabled ? cardStatusOptions : filterCardStatusOptions(get(details, 'status.status'), cardStatusOptions);

    return (
      <>
        {this.isSuspendedByIssuer() && (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12}>
              {this.renderAlert()}
            </Col>
          </Row>
        )}
        <WithCardSelect
          onCardSelected={cardActions.cardSuspensionFormSetValue}
          selected={get(formData, 'selected', [])}
          cardInfo={cardInfo}
          validations={validations}
          customer={customer}
          disabled={disabled}
        />
        <Row style={{ paddingBottom: '20px' }}>
          <Col lg={12} md={12}>
            <SuspensionStatus
              value={get(formData, 'status')}
              options={filteredCardStatusOptions}
              onChange={cardActions.cardSuspensionFormSetValue}
              validations={validations.status}
              disabled={disabled}
            />
          </Col>
        </Row>

        {this.isReasonRequired() ? (
          <Row style={{ paddingBottom: '20px' }}>
            <Col lg={12} md={12}>
              <SuspensionReason
                value={get(formData, 'reason')}
                codes={filterCardSuspensionCodes(suspensionCodes)}
                onChange={cardActions.cardSuspensionFormSetValue}
                validations={validations.reason}
                language={language}
                disabled={disabled}
              />
            </Col>
          </Row>
        ) : null}

        <Row style={{ paddingTop: '10px' }}>
          <Col lg={12} md={12}>
            <Button
              color="primary"
              onClick={this.onSubmit}
              disabled={this.isSuspendedByIssuer() || disabled || isEmpty(get(formData, 'selected', [])) || isEmpty(get(formData, 'status'))}
            >
              <FormattedMessage id={get(formData, 'status') === 'active' ? 'Card.Button.RemoveSuspension' : 'Card.Button.SetSuspension'} />
            </Button>

            <span style={{ paddingLeft: '10px' }} />

            <Button color="primary" outline onClick={redirect}>
              <FormattedMessage id="Card.Button.Cancel" />
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
CardSuspension.propTypes = {
  cardActions: PropTypes.object.isRequired,
  codes: PropTypes.object,
  customer: PropTypes.object,
  formData: PropTypes.object,
  intl: PropTypes.object.isRequired,
  language: PropTypes.string,
  redirect: PropTypes.func,
  session: PropTypes.object.isRequired,
  // prop from higher order component
  activity: PropTypes.object,
  cardInfo: PropTypes.object,
  workguide: PropTypes.object
};

CardSuspension.defaultProps = {
  codes: { groups: {} },
  customer: undefined,
  language: 'de',
  redirect: noop,
  cardInfo: {},
  workguide: undefined,
  formData: {},
  activity: undefined
};

function mapStateToProps(state) {
  const { login, codes } = state;
  return {
    language: login.language,
    codes,
    session: login.session,
    suspend: state.card.suspend,
    requesting: !codeUtils.allGroupsFulfilledOrRejected(state.codes),
    formData: state.card.cardSuspensionForm.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    cardActions: bindActionCreators(cardActions, dispatch)
  };
}

export default withWorkguideInstanceData()(

  withCodeGroups({ groups: ['creditCardSuspension'] })(
    // fetch customer cards with described details
    withCustomerCardData({ details: ['info', 'status'], allowedCardTypes: cardTypeMap.get('credit') })(
      connect(mapStateToProps, mapDispatchToProps)(
        injectIntl(CardSuspension)
      )
    )
  )
);
